import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import ThreeCol from "../components/threeCol/threeCol"
import GreenBar from "../images/green-bar.jpg"
import TxtImg from "../components/txtImg/txtImg"
import Form from "../components/form/form"
import styled from "styled-components"
import Img from "gatsby-image"
import { Parallax } from "react-parallax"
import Button from '../components/Button/button'
import { Helmet } from "react-helmet"

const IntroArea = styled.div`
  width: 80%;
  max-width: 1080px;
  margin: auto;
  padding: 1em 0 2em 0;
  display: block;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: 980px) {
      display: flex;
  }
`;

const IntroContainer = styled.div`
  width: 100%;
  background-color: var(--neutral-translucent);
  padding: 20px;
  border-radius: 2px;
  font-size: var(--type-xs);
  align-self: flex-end;

  h1{
    font-size: var(--type-xxxl);
    font-weight: 700;
    margin-block-end: 0;
    margin-block-start: 0.3em;
    line-height: 1em;
    color: var(--accent-turquoise-500);
    text-transform: none;
    @media only screen and (min-width: 980px) {
      font-size: var(--type-xxxl);
      display: block;
      width: 80%;
    }
  }

  @media only screen and (min-width: 980px) {
      width: 47.25%;
      padding: 20px 40px;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  text-align: center;
  
  
  @media only screen and (min-width: 980px) {
      width: 47.25%;
  }
`;

const CapLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: var(--accent-orange-500);
  &:hover {
    text-decoration: underline;
  }
`

const Testimonial = styled.section`
  width: 80%;
  max-width: 1080px;
  margin: 0 auto;
  display: block;
  padding: 2rem 0;
  text-transform: uppercase;
  div.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    width: fit-content;
    margin: auto;
    text-align: center;
    @media only screen and (min-width: 790px) {
      grid-template-columns: 1fr 130px;
    }
  }
  div.underline {
    margin-top: -15px;
  }
  div.imageContainer {
    justify-self: center;
    width: 130px;
  }
  p.testimonialText {
    font-size: 26px;
  }
`;

const NewsSection = styled.div`
  width: 80%;
  margin: 0 auto;
  max-width: 1080px;
  padding-bottom: 3rem;
  h1 {
    color: white;
    margin-top: -30px;
  }
  @media only screen and (min-width: 980px) {
    h1 {
      margin-top: 0;
    }
  }
`;

const LatestNews = styled.div`
  display: block;
  @media only screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 30px;
    padding: 30px 0;
  }
`;

const NewsCard = styled.div`
  background-color: white;
  height: 100%;
  margin: 50px 0;
  box-shadow: var(--shadow-b);
  @media only screen and (min-width: 900px) {
    margin: 0;
    .newsImg {
      height: 205px;
      width: auto;
    }
  }
`;

const NewsCardText = styled.div`
  height: calc(100% - 205px);
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NewsTitle = styled.h2`
  color: var(--neutral-800);
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
`;

const Exerpt = styled.p`
 font-size: 18px;
 color: var(--neutral-600);
`;

const NewsLink = styled(Link)`
  width: fit-content;
  color: var(--accent-turquoise-400);
`;

const TxtImgArea = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    display: block;
    align-items: center;
    justify-content: space-between;
    @media only screen and (min-width: 980px) {
        display: flex;
    }
`;

const TxtContainer = styled.div`
    width: 100%;
    @media only screen and (min-width: 980px) {
        width: 47.25%;
    }
`;

const Media = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h1 {
    text-align: center;
    color: var(--neutral-100);
  }
`;

const MediaLogos = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: auto;
`;

const BgGradientGrass = styled.section`
  padding: 0rem;
  @media only screen and (min-width: 1001px) {
    background-image: linear-gradient(-2deg,#B0CA3D 0%, #B4CD3F 47%, #e9f2f2 47.2%)!important;
  }
  @media only screen and (max-width: 1000px) and (min-width:801px) {
    background-image: linear-gradient(-2deg,#B0CA3D 0%,#B4CD3F 32%,#e9f2f2 32.2%)!important;
  }
  @media only screen and (max-width: 800px) {
    background-image: linear-gradient(-2deg,#B0CA3D 0%,#B4CD3F 22%,#e9f2f2 22.2%)!important;
  }
`;

const BgGradientOrange = styled.section`
  background-image: linear-gradient(-1.5deg, var(--accent-orange-600) 10%, var(--neutral-100) 10.2%)!important;
  padding-bottom: 5em;
  margin-bottom: -2em;

  @media only screen and (min-width: 900px) {
    margin-bottom: -1em;
    padding-bottom: 2.5em;
  }
`;

const BgSplit = styled.div`
  height: 100px;
  background-image: linear-gradient(1deg, var(--neutral-200) 48%, var(--accent-green-400) 48.2%)!important;
`;


export default function Home({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="description" content="Caplin Solar provide an award winning system that makes it practical and affordable for new homes to achieve zero carbon status." />
        <title>Caplin Solar | Thermal Energy Storage</title>
        <html lang="en"></html>
      </Helmet>
      <BgGradientGrass>
      <IntroArea>
        <IntroContainer>
            <h1>Switching on to solar heat.</h1>
            <p>Sunshine is the most <span style={{color:"var(--accent-turquoise-500"}}>clean</span>, <span style={{color:"var(--accent-green-500)"}}>green</span>, & <span style={{color:"var(--accent-orange-500)"}}>reliable</span> energy source. The only problem is: It’s most available when least needed.. and least available when most needed.</p>
            <p>Our innovative inter-seasonal thermal storage technology, for the first time, makes it both practical and affordable to achieve zero carbon status for new homes. The award-winning system is fully integrated and can meet a home’s full annual hot water and heating requirements using just solar energy.</p>
        </IntroContainer>
        <ImgContainer>
            <Img fluid={data.systemImage.childImageSharp.fluid} alt="Caplin Solar system illustration" />
        </ImgContainer>
      </IntroArea>
      </BgGradientGrass>
      <BgSplit />
      <section style={{backgroundColor: "var(--neutral-200)", paddingBottom: "0.5rem"}}>
        <div style={{width: "80%", margin: "auto", maxWidth: 820}}>
            <h1 style={{textAlign: "center"}}>Integrated Solar Technology For New Homes</h1>
            <div className="underline center"></div>
            <p>Displacing conventional renewable energy technologies for new buildings, the breakthrough development of a practical and low cost form of inter-seasonal heat storage, the​ <CapLink to="/technology/storage">Earth Enegy Bank</CapLink> (EEB), has made it easy to store summer-time heat in the earth below buildings for use the following winter.</p>
            <p>This innovation is combined with two other renewable technologies to form an integrated system that opens the door to cost-effective​ zero carbon buildings. A hybrid solar array, also known as PV-Thermal or PV-T, enables much more solar energy to be collected than conventional PV or thermal arrays. Its panels deliver four times the energy per sq m than PV by extracting both heat and electricity from the same panel. In winter, the stored heat is extracted from the EEB using a ground source heat pump.</p>
        </div>
      </section>
      <ThreeCol type="Technology" concise={false} />
      <BgGradientOrange>
        <Testimonial>
            <div className="gridContainer">
              <p className="testimonialText">"Most successful integrated renewable system"</p>
              <div className="imageContainer">
                <Img fluid={data.sppImage.childImageSharp.fluid} alt="Solar Power Portal Awards logo" />
              </div>
              <div className="underline center heightless"></div>
            </div>
        </Testimonial>
        <section>
          <TxtImg heading="Independent research" linkTo="/research" linkName="Learn more" imgSrc={data.graphImage.childImageSharp.fluid} imgAlt="Illustrated graph">
            <p>Since 2013 experts from De Montfort University’s <a href="http://www.dmu.ac.uk/research/research-faculties-and-institutes/institute-of-energy-and-sustainable-development/institute-of-energy-and-sustainable-development.aspx" target="_blank" rel="noopener noreferrer">IESD</a>, a world leading energy research institute, have undertaken studies into the technical performance of the Caplin Solar thermal storage system.</p>
          </TxtImg>
        </section>
      </BgGradientOrange>
      <section style={{backgroundColor: "var(--accent-orange-600)"}}>
        <NewsSection>
          <h1 style={{textAlign: "center"}}>Latest news</h1>
          <div className="underline center" style={{borderTop: "3px solid var(--neutral-100"}}></div>
          <LatestNews>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <NewsCard key={node.id}>
                <Img className="newsImg" fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />
                <NewsCardText>
                  <NewsTitle>{node.frontmatter.title}</NewsTitle>
                  <Exerpt>{node.excerpt}</Exerpt>
                  <NewsLink to={node.fields.slug}>Read article</NewsLink>
                </NewsCardText>
              </NewsCard>
            ))}
          </LatestNews>
        </NewsSection>
      </section>
      <section style={{backgroundColor: "var(--neutral-100)"}}>
        <TxtImgArea>
          <TxtContainer>
          <Img fluid={data.chLogo.childImageSharp.fluid}  alt="Caplin Homes logo" style={{maxWidth: "62%"}}/>
          <p>When employed in houses with the appropriate building fabric, our system can give better returns on investment than​ any other combination of renewable technologies.</p>
          <p>Caplin Homes specialise in building the most energy efficient timber frame houses. They dramatically reduce heating and maintenance costs and yet are no more expensive to buy than conventional homes.</p>
          <Button to="https://caplinhomes.co.uk" backgroundColor="#a1bf34" isLink>FIND OUT MORE</Button>
          </TxtContainer>
          <ImgContainer>
              <Img fluid={data.stoughtonImage.childImageSharp.fluid}  alt="A Caplin Homes timber frame house" />
          </ImgContainer>
        </TxtImgArea>
      </section>
      <Parallax bgImage={GreenBar} strength={200}>
        <Media>
          <h1>As seen in</h1>
          <div className="underline center"></div>
          <MediaLogos>
            <Img fluid={data.logosImage.childImageSharp.fluid} alt="Media company logos" />
          </MediaLogos>
        </Media>
      </Parallax>
      <section>
        <Form />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
            featuredImage {
              childImageSharp {
                  fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid_noBase64
                  }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    systemImage: file(relativePath: {eq: "images/CS_System2.png"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sppImage: file(relativePath: {eq: "images/spp-awards.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 130, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    chLogo: file(relativePath: {eq: "images/caplinhomes_logo.png"}) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    stoughtonImage: file(relativePath: {eq: "images/CPHsm.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logosImage: file(relativePath: {eq: "images/logos.png"}) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    graphImage: file(relativePath: {eq: "images/writer-illustration.png"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`