import React from "react"
import styled from 'styled-components';
import Contact from "../../images/contact.svg"
import Button from "../Button/button"

const SectionContainer = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    display: block;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: start;

    @media only screen and (min-width: 980px) {
        display: grid;
    }
`;

const FormContainer = styled.div`
    width: 100%;
    h1 {
        text-transform: uppercase;
        font-size: 24px;
    }
    form {
        display: flex;
        flex-direction: column;
    }
`;

const EnquiryType = styled.div`
    background-color: inherit;
    position: relative;

    ::after {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 0;
        height: 0;
        margin-top: 3px;
        border: 6px solid;
            border-top-color: currentcolor;
            border-right-color: currentcolor;
            border-bottom-color: currentcolor;
            border-left-color: currentcolor;
        border-color: var(--neutral-600) transparent transparent transparent;
        content: "";
        transform: translateY(-50%);
        pointer-events: none;
    }

    select {
        background-color: var(--neutral-100);
    }
`

const ImgContainer = styled.div`
    padding: 30px 0;
    align-self: center;
    img{
        width: 95%;
        height: auto;
        padding-left: 5%;
    }
`;

export default function Form(props) {
    return (
            <SectionContainer>
                <FormContainer>
                    <h1 style={{color: props.headingColor === "white" ? "var(--neutral-100)" : "var(--neutral-900)"}}>Let's start a conversation</h1>
                    <div className="underline" style={{borderTopColor: props.headingColor === "white" ? "var(--neutral-100)" : "var(--accent-orange-300)"}}></div>
                    <form method="post" action="/success" netlify-honeypot="bot-field" data-netlify="true" name="contact1">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="contact1" />
                        <input id="name" name="name" type="text" placeholder="Name" required />
                        <input id="email" name="email" type="email" placeholder="Email" required />
                        <EnquiryType>
                            <select id="subject" name="subject">
                                <option value="subject">Subject</option>
                                <option value="customer-enquiry">Customer enquiry</option>
                                <option value="business-enquiry">Business enquiry</option>
                                <option value="general-enquiry">General enquiry</option>
                            </select>
                        </EnquiryType>
                        <textarea id="message" name="message" placeholder="Message" required></textarea>
                        <Button isFullwidth type="submit" id="submit" value="SEND MESSAGE">Send Message</Button>
                    </form>
                </FormContainer>
                <ImgContainer>
                    <img src={Contact} alt="Contact methods illustration" width="705px" height="404px" />
                </ImgContainer>
            </SectionContainer>
    )
}